import PublicationsListing from './PublicationsListing';
import withGlobalAppLayout from '../../containers/HOC/withGlobalAppLayout';
import { ReactElement } from 'react';

export default function index(props: any): ReactElement
{
    return (
        withGlobalAppLayout(PublicationsListing, {
            ...props,
            locale: props.pageContext.locale,
            setDrawerStatus: () => false,
            setUser: () => false,
            appDrawerIsOpen: false,
            pathname: '',
            hideGfLogo: false,
        })
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class PageHeader extends React.Component
{
    determineText()
    {
        const {
            title,
            translateTitle,
        } = this.props;

        if (translateTitle)
        {
            return <FormattedMessage id={title} />;
        }

        return title;
    }

    renderHrContent()
    {
        const {
            showHr,
            hrStyle,
        } = this.props;

        if (showHr)
        {
            return <hr style={hrStyle || { marginTop: '1rem' }}/>;
        }

        return null;
    }

    render()
    {
        const { rootElementClassName } = this.props;

        return (
            <div className="page-header-wrapper">
                <h1 className={rootElementClassName || 'page-header-title'}>{this.determineText()}</h1>
                {this.renderHrContent()}
            </div>
        );
    }
}

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    translateTitle: PropTypes.bool,
    showHr: PropTypes.bool,
    hrStyle: PropTypes.object,
    rootElementClassName: PropTypes.string,

};

export default PageHeader;

import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';
import { Chip } from '@mui/material';
import * as style from './index.module.css';

class PublicationsSearchSection extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            searchOptionsActive: false,
        };
    }

  toggleSearchOptions = () =>
  {
      this.setState({
          searchOptionsActive: !this.state.searchOptionsActive,
      });
  }

  onSubmit = (e) =>
  {
      e.preventDefault();
      this.props.handleSearchSubmit(this.props.searchTerm);
  }

  render()
  {
      const {
          searchTerm = '',
          tag,
          clearTag,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          setSearchTerm = (term) => false,
      } = this.props;

      return (
          <div className={style.publications_search_section} >
              <form onSubmit={this.onSubmit} className="simple-search-form">
                  <TextField
                      id="filled-full-width"
                      label={<FormattedMessage id="search_through"/>}
                      style={{ margin: 8, width: '100%' }}
                      color="primary"
                      placeholder="&nbsp; Titel, Autor, Thema"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                  />
                  {searchTerm ?
                      <span>
                          <FormattedMessage id="searching_for"/>
                          <Chip label={searchTerm} style={{ marginRight: '8px' }} onDelete={() => setSearchTerm('')}/>
                      </span> : null
                  }
                  {tag ?
                      <span>
                          Tag:
                          <Chip style={{ margin: '3px' }} label={tag} onDelete={() => clearTag()}/>
                      </span> : null
                  }
              </form>
          </div>
      );
  }
}

PublicationsSearchSection.propTypes = {
    setSearchTerm: PropTypes.func,
    searchTerm: PropTypes.string,
    handleSearchSubmit: PropTypes.func,
    tag: PropTypes.string,
    clearTag: PropTypes.func,
};

export default PublicationsSearchSection;

import React, { ReactElement } from 'react';

import { FormattedMessage } from 'react-intl';
import qs from 'querystring';

import CardList from '../../molecules/CardList';
import PublicationsSearchSection from '../../molecules/Publication/PublicationsSearchSection';
import PageHeader from '../../atoms/PageHeader';
import Head from '../../atoms/Head';

import globalComponentMountOperations from '../../../infrastructure/utilities/globalComponentMountOperations';
import Module from '../../../application/Service/Module';
import RouteGenerator from '../../../infrastructure/Navigation/RouteGenerator';
import { TLocale } from '../../../infrastructure/Type/Locale';
import { GFBrand } from '../../models/GFBrand';

export interface IPublicationPageContextProps {
    locale: TLocale;
    isPublicationsLoading: boolean;
    // @todo [refactor]  any
    publicationsDe: any[];
    publicationsEn: any[];
}

export interface IPublicationListingProps {
    pageContext: IPublicationPageContextProps;
    // @todo [refactor]
    navigate: (to: any, options?: any) => void;
    location: Location;
}

export interface IPublicationListingState {
    searchTermInput: string;
    appliedSearchTerm: string;
}

class PublicationsListing extends React.Component<IPublicationListingProps, IPublicationListingState>
{
    public constructor(props: IPublicationListingProps)
    {
        super(props);
        this.state = {
            searchTermInput: this.parseSearchTermQuery() as string || '',
            appliedSearchTerm: this.parseSearchTermQuery() as string || '',
        };
    }

    public updateSearchTermTimer: number | null = null;

    public componentDidMount(): void
    {
        globalComponentMountOperations.handleRouteComponentMount();
    }

    public parseSearchTermQuery = (): string =>
    {
        // @todo [refactor] replace with new native
        return qs.parse(this.props.location.search.replace('?', '')).search as string;
    }

    public setSearchTerm = (newTerm: string) =>
    {
        this.setState({
            searchTermInput: newTerm,
        });

        if (this.updateSearchTermTimer)
        {
            Module.get().clearTimeout(this.updateSearchTermTimer);
        }

        this.updateSearchTermTimer = Module.get().setTimeout(() =>
        {
            this.setState({
                appliedSearchTerm: newTerm,
            });
        }, 500);
    }

    private resolveTagTranslation(tagSlug: string): string | null
    {
        const { pageContext: { publicationsDe, locale } } = this.props;
        let tag = null;
        publicationsDe.forEach((_publication: any) =>
        {
            _publication.tags.forEach((_tag: Record<string, string>) =>
            {
                if (_tag.slug === tagSlug)
                {
                    tag = _tag;
                }
            });
        });
        if (!tag)
        {
            return null;
        }

        return tag[locale === 'en' ? 'titleEn' : 'titleDe'] || null;
    }

    private getFilteredPublications(tag: string|null): any[]
    {
        const { pageContext: { publicationsDe, publicationsEn, locale } } = this.props;
        const { appliedSearchTerm } = this.state;

        let publicationList = locale === 'en' ? publicationsEn : publicationsDe;
        publicationList = publicationList.sort((a, b) => b.publishToGlaubensfutterDate - a.publishToGlaubensfutterDate);

        if (tag)
        {
            publicationList = publicationList.filter((publication: any ) =>
            {
                return publication.tags.find((tagFromList: {slug: string}) =>
                {
                    return tagFromList.slug === tag;
                });
            });
        }

        const adjustedSearchTerm = appliedSearchTerm.toLowerCase().trim();

        if (!adjustedSearchTerm)
        {
            return publicationList;
        }

        return publicationList.filter((publication: any) =>
        {
            if (publication.title?.toLowerCase().includes(adjustedSearchTerm))
            {
                return true;
            }

            if (publication.description?.toLowerCase().includes(adjustedSearchTerm))
            {
                return true;
            }

            if (publication.articleBodyMarkdown?.toLowerCase().includes(adjustedSearchTerm))
            {
                return true;
            }

            if (publication.originalAuthor?.toLowerCase().includes(adjustedSearchTerm))
            {
                return true;
            }

            return false;
        });
    }

    public render(): ReactElement
    {
        const {
            pageContext: { locale },
            location,
            navigate,
        } = this.props;

        const tag = (qs.parse(location.search.replace('?', '')).tag as string);
        const filteredPublications = this.getFilteredPublications(tag);

        if (!filteredPublications.length)
        {
            return (
                <section className="publications-container">
                    <Head
                        locale={locale}
                        ogImage="/wahrheit.jpg"
                        ogTitle={`Glaubensfutter - ${locale === 'de' ? 'Publikationen' : 'Publications'}`}
                        ogDescription={GFBrand.BRAND_DESCRIPTION}
                        ogUrl={RouteGenerator.forPublicationsListing(locale).getFullUrl()}
                    />
                    <PageHeader
                        title="publications"
                        translateTitle={true}
                    />
                    <PublicationsSearchSection
                        setSearchTerm={this.setSearchTerm}
                        searchTerm={this.state.searchTermInput}
                        handleSearchSubmit={this.setSearchTerm}
                        tag={this.resolveTagTranslation(tag) || tag}
                        clearTag={() => navigate(locale === 'en' ? '/en/publikationen' : '/publikationen')}
                    />
                    <div className="publications-cards-container">
                        <h3 style={{ marginTop: '5rem', fontSize: '1rem' }}><FormattedMessage id="nothing_found"/></h3>
                    </div>
                </section>
            );
        }

        return (
            <section className="publications-container">
                <Head
                    locale={locale}
                    ogImage={RouteGenerator.forPublicUrl('/wahrheit.jpg')}
                    ogTitle={`Glaubensfutter - ${locale === 'de' ? 'Publikationen' : 'Publications'}`}
                    ogDescription={GFBrand.BRAND_DESCRIPTION}
                    ogUrl={RouteGenerator.forPublicationsListing(locale).getFullUrl()}
                />
                <PageHeader
                    title="publications"
                    translateTitle={true}
                />
                <PublicationsSearchSection
                    setSearchTerm={this.setSearchTerm}
                    searchTerm={this.state.searchTermInput}
                    handleSearchSubmit={this.setSearchTerm}
                    tag={this.resolveTagTranslation(tag) || tag}
                    clearTag={() => navigate(locale === 'en' ? '/en/publikationen' : '/publikationen')}
                />
                <div className="publications-cards-container">
                    <CardList locale={locale} cardListConfig={filteredPublications} cardType="publicationCard"/>
                </div>
            </section>
        );
    }
}

export default PublicationsListing;
